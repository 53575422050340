<template>
  <b-container class="pt-4 px-0">
    <div v-if="!loaded">
      <h3 class="d-flex justify-content-center mb-3">
        Please wait while we gather your info
      </h3>

      <div class="d-flex justify-content-center mb-3">
        <ct-centered-spinner />
      </div>
    </div>
    <b-container
      v-else
      id="order-confirmation-component"
      class="p-0"
    >
      <div v-if="!showPhoneService">
        <confirmation-header
          :invoice="invoice"
          @logInteraction="logInteraction"
        />
        <br>
      </div>

      <phone-service v-if="showPhoneService" />

      <registered-agent-service
        v-if="showRAService"
        :services="invoice.services"
      />

      <p v-if="orderHasRAService && orderHasNoFilingItems">
        Whether you are trying to form a new company, change your existing Registered Agent,
        or registering your company to do business in another state, we can help!
      </p>

      <hr>

      <div v-if="showSetupBizIdServices" class="button-container">
        <primary-button
          button-text="Continue Setup of Services"
          @onclick="navigateToOnlinePresenceStage"
        />
      </div>
      <div v-else-if="orderIsOnlyLegalDocs" class="button-container">
        <primary-button
          button-text="Continue to PDF Filler"
          @onclick="navigateToFinalizeLegalDocs"
        />
      </div>

      <div v-else-if="showButtonContainer" class="button-container">
        <button
          class="outline"
          @click="printPage()"
        >
          <feather-icon type="printer" />
          Print
        </button>

        <div>
          <button
            v-if="orderHasRAService && orderHasNoFilingItems"
            class="outline"
            @click="navigateHome"
          >
            {{ homeButtonText }}
          </button>

          <primary-button
            v-else
            :button-text="homeButtonText"
            @click="navigateHome"
          />
        </div>

        <primary-button
          v-if="orderHasRAService && orderHasNoFilingItems"
          :button-text="'Help Me With My Filing'"
          @onclick="navigateToSelectDomesticRegistration()"
        />
      </div>
    </b-container>
  </b-container>
</template>

<script>
    import axiosClient from '@/http'
    import { mapGetters, mapActions } from 'vuex'
    import {
      completeInteraction,
      createOrFindClientInteractionLog,
      logClientInteraction,
    } from '@/common/modules/clientInteractionLog'

    export default {
      name: "OrderConfirmation",

      components: {
        CtCenteredSpinner:      () => import('@/components/shared/CtCenteredSpinner'),
        PhoneService:           () => import('@/components/HireUs/OrderConfirmation/PhoneService'),
        ConfirmationHeader:     () => import('@/components/HireUs/OrderConfirmation/ConfirmationHeader'),
        RegisteredAgentService: () => import('@/components/HireUs/OrderConfirmation/RegisteredAgentService'),
        FeatherIcon:            () => import('@/components/shared/FeatherIcon'),
        PrimaryButton:          () => import('@/components/StagelineV2/shared/PrimaryButton'),
      },

      data() {
        return {
          showPhoneService: false,
          showBizIdSetup: false,
          showRAService: true,
          raProducts: [],
          loaded: false,
          clientInteractionLog: null,
        }
      },

      computed: {
        ...mapGetters('checkout', ['context']),
        ...mapGetters('website', ['website']),
        ...mapGetters('invoices', { findInvoice: 'find' }),
        homeButtonText() {
          return this.showPhoneService ? 'Set Up My Phone' : 'Return Home'
        },
        invoice() {
          return this.findInvoice(this.$route.params.invoiceId)
        },
        orderHasNoFilingItems() {
          return !this.invoice?.order?.order_items ||
            this.invoice?.order?.order_items?.every(oi => oi.type !== 'filing')
        },
        orderHasFilingItemRequiringAttention() {
          return this.invoice.order?.order_items?.some(oi => {
            return oi.type === 'filing' &&
              (['awaiting-client-input', 'order-verification-required'].includes(oi.status))
          })
        },
        orderHasRAService() {
          return this.invoice?.invoice_line_items?.filter(item => item.product.kind === 'registered_agent_product').length
        },
        orderHasBizIdHostingProduct() {
          return this.invoice?.invoice_line_items?.some(item => ['business-email', 'business-website-hosting'].includes(item.product?.category))
        },
        orderIsOnlyLegalDocs() {
          const legalDocNames = ['Premium Operating Agreement', 'Premium Corporate Bylaws']
          return this.stagelineV2Enabled &&
            !this.orderHasFilingItemRequiringAttention &&
            this.invoice?.invoice_line_items?.every(item => legalDocNames.includes(item.product?.name))
        },
        showButtonContainer() {
          return this.orderHasNoFilingItems || !this.orderHasFilingItemRequiringAttention
        },
        stagelineV2Enabled() {
          return this.website?.stageline_enabled && this.website.stageline_version === 2
        },
        showSetupBizIdServices() {
          return this.stagelineV2Enabled &&
            !this.orderHasFilingItemRequiringAttention &&
            this.showBizIdSetup
        },
      },

      async mounted() {
        const productRes = await axiosClient.get(`client/products/registered_agent_products`, { params:{ include_address: true } })
        this.raProducts = productRes.data.result
        await this.loadIfNotAvailableById({ ids: this.$route.params.invoiceId, params: { include_details: true } })

        this.showBizIdSetup = this.invoice?.has_business_identity_bundle || this.orderHasBizIdHostingProduct

        this.showPhoneService = this.invoice.invoice_line_items.filter(item => item.product.name === 'Phone Service').length && !this.showBizIdSetup
        if (this.showPhoneService) {
          this.showRAService = false
        } else if (this.invoice.order) {
          this.showRAService = !this.invoice.order.order_items.filter(item => item.product.filing_name === 'form a company' || item.product.filing_name === 'register a company').length
        }

        if (this.showBizIdSetup) {
          await this.fetchCompanyVouchers(this.invoice.company_id)
        }

        await this.createClientInteraction()
        this.loaded = true
      },

      methods: {
        ...mapActions('invoices', ['loadIfNotAvailableById']),
        ...mapActions('vouchers', ['fetchCompanyVouchers']),

        async createClientInteraction() {
          try {
            const category = 'hire-us'
            const subCategory = this.determineClientInteractionSubCategory()

            this.clientInteractionLog = await createOrFindClientInteractionLog({
              category: category,
              subCategory: subCategory,
              objectId: this.invoice.id,
              objectTable: 'Invoice',
              companyId: this.invoice.company_id,
            })
          } catch (error) {
            // Just suppressing the error
          }
        },

        determineClientInteractionSubCategory() {
          if (this.orderHasFilingItemRequiringAttention) {
            return 'filing-confirmation'
          } else if (this.orderHasRAService) {
            return 'registered-agent-confirmation'
          } else if(this.showSetupBizIdServices) {
            return 'business-identity-confirmation'
          } else {
            return 'generic-confirmation'
          }
        },

        async logInteraction(type, action, name) {
          try {
            this.clientInteractionLog = await logClientInteraction({
              clientInteractionLogId: this.clientInteractionLog.id,
              interaction: { type, action, name },
            })
          } catch (error) {
            // Just suppressing the error
          }
        },

        async completeClientInteraction(type, action, name) {
          try {
            this.clientInteractionLog = await completeInteraction({
              id: this.clientInteractionLog.id,
              interaction: { type, action, name },
              completed: true,
            })
          } catch (error) {
            // Just suppressing the error
          }
        },

        async navigateHome() {
          await this.completeClientInteraction('button', 'redirect', 'redirect-dashpanel')
          await this.$router.push({ name: 'dashpanel' })
        },

        async printPage() {
          await this.logInteraction('button', 'print', 'print-order-confirmation')
          setTimeout(window.print(), 1000)
        },

        async navigateToOnlinePresenceStage() {
          await this.completeClientInteraction('button', 'redirect', 'redirect-online-presence-stage')
          await this.navigateToStage('online_presence')
        },
        async navigateToFinalizeLegalDocs() {
          await this.completeClientInteraction('button', 'redirect', 'redirect-finalize-legal-docs')
          await this.navigateToStage('finalize_legal_documents')
        },
        async navigateToStage(stageName) {
          this.$router.push({
            name: 'stageline-v2-stage',
            params: {
              companyId: this.invoice.company_id,
              stageIdOrName: stageName,
            },
          }).catch(()=>{})
        },
        async navigateToSelectDomesticRegistration() {
          await this.completeClientInteraction('button', 'redirect', 'stageline-decision-tree')
          await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.invoice.company_id } })
        },
      },
    }
</script>

<style lang="scss" scoped>
$black: #231F20;

@mixin primary-buttons($background: $ct-ui-primary, $color: white) {
  background: $background;
  color: $color;
  border: 0.0625em solid $ct-ui-primary;
  border-radius: 0.25em;
  padding: 0.5em 1.875em;
  font-weight: 700;

  &:hover{
    border-color: $ct-ui-primary-dark;
    background: $ct-ui-primary-dark;
    color: white;
  }
}

h3 {
  font-size: 1.5em;
  font-weight: 700;
  color: $black;
}

p {
  font-size: 1.0em;
  font-weight: 400;
}

#order-confirmation-component {
  .button-container {
    padding-top: 0.5em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    column-gap: 1.0em;

    .primary-button {
      padding: 0.5em;
    }

    button.primary {
      @include primary-buttons();
    }

    button.outline {
      @include primary-buttons($background: white, $color: $ct-ui-primary);
    }
  }
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 1.25em;
  }
}

@media print {
  #order-confirmation-component {

    .button-container {
      display: none;
    }
  }
}
</style>
